/* You can add global styles to this file, and also import other style files */
/* Note: It is recommended that you instead edit global styles in the Asset Server project */


/* Third-party libraries */
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

.tippy-content {
	padding: 0px 9px !important;
}
